$(function () {
  // 現在の言語
	let tmp = $('html').attr('lang');
	$("#lang option[value='"+tmp+"']").prop('selected', true);
	
	// 言語切り替え
  $('#lang').change(function(){
  	// 選択された言語
  	let lang = $('#lang').val();
  	// 今の言語と選択された言語が違うなら
  	if(tmp != lang){
  		// 現在のurl
	  	let url = location.pathname
	  	// 正規表現
	  	let regexp = /\/ja|\/en|\/vi|\/zh/;
	  	
	  	// urlに言語pathが含まれるか？
	  	if( regexp.test(url) ){
	  		url = url.replace(tmp,lang);
	  	}else{
	  		if(url.match("/")){
		  		url = '/' + lang;
	  		}else{
		  		url = '/' + lang + url;
	  		}
	  	}
	  	
	  	window.location.href = url;
  	}
  });
  
  // ヘッダーの権限変更時にページ遷移
  $('#authority').change(function(){
  	var str = $(this).val();

    var url;
    switch(str){
    	case 'admin':
    		url = "/manager/top";
    		break;
    	case 'voom':
    		url = "/manager/top";
    		break;
    	case 'manager':
    		url = "/manager/top";
    		break;
    	case 'guest':
    		url = "/";
    		break;
    	default:
    		url = "/";
    }
    window.location.href = url;
  });
	
	//お知らせ表示（全体）
  $(function() {
  	$(".header .info-btn").click(function() {
  		$(".news-overlay").fadeIn(300);
  		return false;
  	});
  
  	$(".news-overlay .confirm-btn").click(function() {
  		$(".news-overlay").fadeOut(300);
  		return false;
  	});
  
  });

  //お知らせ表示（コンテンツ）
  $(function() {
    $(".news-title").click(function() {
      // 全部非表示
      $(".news-detail").hide();
      
      // clickされた内容を表示
      var news_body = $(this).data('body');
      $(`#${news_body}`).show();
    })
  });
	
	// お知らせベルクリック
	$("#news_num").click(function(){
	  // お知らせ通知数を削除
	  $.ajax({
      type: 'GET',
      url: '/del_bells'
    }).done(function (res) {
    })
	});
	
  // doctorページの遷移	
	$(document).ready(function(){
    $("#doctor").change(function(){
      var str = $(this).val();
      if(str == 0){
        window.location.href = '/doctor/users';
      }else{
        window.location.href = '/doctor/users?dco=' + str;
      }
    });
  });
	
  // $(document).ready(function() {
  //   console.log("実行");
  //   var flag_control = 0;
  //   const language = location.pathname.split('/');
    
  //   if(language[1] == 'ja' || language[1] == 'en' || language[1] == 'zh' ){
  //     $('#' + language[1]).addClass('active');
  //   }else{
  //     $('#ja').addClass('active');
  //   }
    
  //   $(".langbox .flag").click(function(){
  //     if(flag_control == 0) {
  //       $('.langbox img').css('display','block');
  //       console.log(1);
  //       flag_control = 1;
  //     } else {
  //       console.log(language[1]);
  //       const tmp = $(this).attr('id');
  //       let url = "";
  //       if(language[1] == 'ja' || language[1] == 'en' || language[1] == 'zh'){
  //         url = location.pathname.replace(language[1], tmp);
  //       }else{
  //         url = '/' + tmp + location.pathname;
  //       }
  //       flag_control = 0;
  //       window.location.href = url;
  //     }
  //   });
  // });
});
